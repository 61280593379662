// TODO: Add prop-type definitions
/* eslint-disable react/prop-types */

import React from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Icon, Menu, Popup } from 'semantic-ui-react';

import executeActionByName from '../../actions/executeActionByName';
import {
  alignElements,
  fitContentCover,
  updateSelectedElements,
} from '../../actions/workspace';
import {
  actionsMeta,
  imageRatios,
  imageTypeLabels,
  itemTypes,
  templateTypeLabels,
} from '../../constants';
import { setIsolation } from '../../modules/controls';
import { historyAnchor } from '../../modules/history';
import { updateSelectedStickers } from '../../modules/sections';
import { getAutoFit } from '../../selectors/controls';
import {
  getApplicableActions,
  getApplicableProps,
  getSelectedElementIds,
  getSelectedElements,
  getSelectedStickerIds,
  getSelectedStickers,
  getSingleSelectedElement,
} from '../../selectors/legacy';
import { objectToOptions } from '../../util/index';
import AlignButtons from './AlignButtons';
import AppearanceInput from './AppearanceInput';
import FilterInput from './FilterInput';
import GlobalSettings from './GlobalSettings';
import ImageRatioSelect from './ImageRatioSelect';
import RichTextToolbar from './RichTextToolbar';
import StickerPlacingSelect from './StickerPlacingSelect';
import SymbolSelect from './SymbolSelect';
import StickerPaddingSelect from './StickerPaddingSelect';

export class ElementToolbarClass extends React.Component {
  applyProperties(delta, anchor = true) {
    const { dispatch, selectedElementIds, selectedStickerIds } = this.props;

    if (selectedStickerIds.length) {
      dispatch(updateSelectedStickers(delta));
    } else if (selectedElementIds.length) {
      dispatch(updateSelectedElements(delta));
    }
    if (anchor) {
      dispatch(historyAnchor());
    }
  }

  propertyChange = (prop, value) => {
    const delta = { [prop]: value };

    this.applyProperties(delta);
  };

  buttonForAction(action) {
    const { dispatch } = this.props;
    const buttonClick = () => {
      dispatch(executeActionByName(action));
      dispatch(historyAnchor());
    };

    const actionMeta =
      actionsMeta.find(([, metaAction]) => metaAction === action) || [];

    const [itemType, , , , icon] = actionMeta;

    if (itemType === itemTypes.spread) {
      return null;
    }

    const props = {
      size: 'mini',
    };

    if (!icon) {
      return (
        <Menu.Item fitted="horizontally" key={action}>
          <Button
            {...props}
            onClick={buttonClick}
            title={action}
            className={`qa-toolbar-${action}-btn`}
          >
            {action}
          </Button>
        </Menu.Item>
      );
    }
    return (
      <Menu.Item fitted="horizontally" key={action}>
        <Button
          {...props}
          onClick={buttonClick}
          title={action}
          className={`qa-toolbar-${action}-btn`}
        >
          <Icon name={icon} />
        </Button>
      </Menu.Item>
    );
  }

  controlForProperty(prop) {
    const {
      autoFit,
      dispatch,
      selectedElements,
      selectedStickers,
    } = this.props;

    let firstElementProps;

    if (selectedElements.length) {
      firstElementProps = selectedElements[0].props;
    } else if (selectedStickers.length) {
      firstElementProps = selectedStickers[0];
    } else {
      return null;
    }

    const value = firstElementProps[prop];

    const selectChange = (e, value) => {
      this.propertyChange(prop, value);
    };

    const dropdownChange = (e, { value }) => {
      this.propertyChange(prop, value);
    };

    const imageRatioChange = (e, { value }) => {
      /**
       * If an image-ratio is selected, update the ratio-prop and set its
       * values. Otherwise clear the ratio in the element props.
       */
      const ratio = imageRatios.find(item => item.value === value);
      const props = ratio ? { ...ratio.props, ratio: value } : { ratio: null };
      this.applyProperties(props);
      if (autoFit) {
        dispatch(fitContentCover());
      }
      dispatch(historyAnchor());
    };

    const alignClick = (e, align, axis) => {
      dispatch(alignElements(align, axis));
      dispatch(historyAnchor());
    };

    const appearanceChange = props => {
      this.applyProperties(props, false);
    };

    const filterChange = value => {
      this.propertyChange(prop, value);
    };

    const props = {
      size: 'mini',
    };

    switch (prop) {
      case 'contentType':
        return (
          <Dropdown
            size="mini"
            placeholder="Typ"
            selection
            value={value}
            options={objectToOptions(imageTypeLabels)}
            onChange={dropdownChange}
            style={{ width: 200 }}
          />
        );
      case 'stickerPlacing':
        return (
          <StickerPlacingSelect
            {...props}
            value={value}
            onChange={selectChange}
          />
        );
      case 'stickerPadding':
        return (
          <StickerPaddingSelect
            {...props}
            value={value}
            onChange={selectChange}
          />
        );
      case 'ratio':
        return (
          <ImageRatioSelect
            {...props}
            value={value}
            onChange={imageRatioChange}
          />
        );
      case 'symbol':
        return (
          <SymbolSelect {...props} value={value} onChange={selectChange} />
        );
      case 'align':
        return <AlignButtons onClick={alignClick} />;
      case 'fillAndBlend':
      case 'fill':
      case 'stroke':
      case 'opacity':
        return (
          <AppearanceInput
            {...firstElementProps}
            mode={prop}
            onChange={appearanceChange}
          />
        );
      case 'filter':
        return (
          <FilterInput
            {...firstElementProps}
            onChange={filterChange}
            value={value}
          />
        );
      case 'text':
        return <RichTextToolbar />;
      default:
        return null;
    }
  }

  render() {
    const {
      applicableActions,
      applicableProps,
      dispatch,
      isolation,
      selectedElements,
      selectedStickers,
      singleSelectedElement,
      templates,
    } = this.props;

    const closeIsolation = isolation && (
      <Menu.Item
        onClick={() => dispatch(setIsolation(null))}
        position="right"
        key="exit"
      >
        <Popup
          inverted
          position="bottom center"
          trigger={<Icon name="close" />}
        >
          Isolation Mode
        </Popup>
      </Menu.Item>
    );

    let type;
    if (singleSelectedElement) {
      type = singleSelectedElement.type;
      if (type === 'Text' && singleSelectedElement.props.symbol) {
        type = `Feld`;
      } else if (type === 'Gruppe' && singleSelectedElement.props.template) {
        const template = templates.find(
          item => item.id === singleSelectedElement.props.template
        );
        if (template) {
          type = `Instanz (${
            templateTypeLabels[template.details.contentType]
          })`;
        }
      }
    } else if (selectedElements.length > 1) {
      type = '(Mehrere Elemente)';
    } else if (selectedStickers.length > 0) {
      type = 'Sticker';
    } else {
      return (
        <Menu onClick={e => e.stopPropagation()} secondary>
          <Menu.Item
            fitted="horizontally"
            key="info"
            className="qa-element-toolbar"
          >
            <b>Settings</b>
          </Menu.Item>
          <GlobalSettings />
          {closeIsolation}
        </Menu>
      );
    }

    return (
      <Menu onClick={e => e.stopPropagation()} secondary>
        {applicableProps.map(prop => {
          const control = this.controlForProperty(prop);
          return control ? (
            <Menu.Item fitted="horizontally" key={prop}>
              {control}
            </Menu.Item>
          ) : null;
        })}

        {applicableActions.map(action => this.buttonForAction(action))}
        {closeIsolation}
      </Menu>
    );
  }
}

const mapStateToProps = state => ({
  alignTo: state.controls.alignTo,
  applicableProps: getApplicableProps(state),
  applicableActions: getApplicableActions(state),
  selectedElements: getSelectedElements(state),
  singleSelectedElement: getSingleSelectedElement(state),
  selectedStickers: getSelectedStickers(state),
  selectedStickerIds: getSelectedStickerIds(state),
  selectedElementIds: getSelectedElementIds(state),
  templates: state.templates,
  isolation: state.controls.isolation,
  autoFit: getAutoFit(state),
});

export default connect(mapStateToProps)(ElementToolbarClass);
