import React from 'react';

import { stickerPaddings, symbolTypes } from '../../constants';
import { newTextElementContentState } from '../../util/draftjs';
import ElementItem from './ElementItem';
import Folder from './Folder';
import FolderList from './FolderList';
import TextItem from './TextItem';

const Elements = [
  {
    template: {
      type: 'Circle',
      props: {
        width: 100,
        height: 100,
        fill: '#888',
      },
    },
    icon: 'circle outline',
  },
  {
    template: {
      type: 'Rectangle',
      props: {
        width: 100,
        height: 100,
        fill: '#888',
      },
    },
    icon: 'square outline',
  },
  {
    template: {
      type: 'Line',
      props: {
        width: 100,
        height: 1,
        fill: 'none',
        stroke: '#888',
        strokeWidth: 1,
      },
    },
    icon: 'minus',
  },
  {
    template: {
      type: 'Text',
      props: {
        width: 100,
        height: 20,
        text: newTextElementContentState,
      },
    },
    icon: 'font',
  },
  {
    template: {
      type: 'Image',
      props: {
        width: 100,
        height: 100,
        rotation: 0,
        image: null,
      },
    },
    icon: 'image',
  },
  {
    template: {
      type: 'StickerArea',
      props: {
        width: 300,
        height: 200,
        stickerPadding: stickerPaddings.small,
      },
    },
    icon: 'grid layout',
  },
  {
    template: {
      type: 'Symbol',
      props: {
        width: 8.26,
        height: 8.85,
        symbol: 'StickerstarsStickerLogo',
        fill: '#888',
      },
    },
    icon: 'star',
  },
];

const ElementsPanel = () => (
  <div className="flex grow qa-elements-content">
    <FolderList>
      <Folder title="Elemente" horizontal={false}>
        {[
          Elements.map((element, index) => (
            <ElementItem
              key={index}
              template={element.template}
              icon={element.icon}
            />
          )),
        ]}
      </Folder>

      <Folder title="Felder" horizontal={false}>
        {Object.keys(symbolTypes).map(symbol => {
          return (
            <TextItem
              key={symbol}
              label={symbolTypes[symbol]}
              symbol={symbol}
            />
          );
        })}
      </Folder>
    </FolderList>
  </div>
);

export default ElementsPanel;
