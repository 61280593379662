import React, { forwardRef, useState } from 'react';

function SvgImage(
  {
    id,
    src,
    previewSrc,
    width,
    height,
    filters,
    flip,
    isPdf,
    rendering,
    preserveAspectRatio,
  },
  ref
) {
  let image;
  const [isFullyLoaded, setIsFullyLoaded] = useState(false);
  const visibility = rendering || isFullyLoaded ? 'visible' : 'hidden';

  if (rendering && isPdf) {
    image = <g ref={ref} />;
  } else {
    image = (
      <g>
        {!rendering && previewSrc && (
          <image
            className="qa-preview-image"
            width={width}
            height={height}
            preserveAspectRatio={preserveAspectRatio}
            xlinkHref={previewSrc}
            filter={filters ? `url(#filter-${id})` : ''}
          />
        )}

        <image
          className="svg-image qa-svg-image"
          style={{ visibility }}
          width={width}
          height={height}
          preserveAspectRatio={preserveAspectRatio}
          xlinkHref={src}
          filter={filters ? `url(#filter-${id})` : ''}
          onLoad={() => setIsFullyLoaded(true)} // eslint-disable-line react/no-unknown-property
        />
      </g>
    );
  }
  if (flip) {
    image = <g transform={`scale(-1,1) translate(${-width},0)`}>{image}</g>;
  }

  return image;
}

SvgImage.defaultProps = {
  preserveAspectRatio: 'none',
  isPdf: false,
};

export default forwardRef((props, ref) => SvgImage(props, ref));
