import React from 'react';
import { func, number } from 'prop-types';
import { Button } from 'semantic-ui-react';

import { stickerPaddings } from '../../constants';

function StickerPaddingSelect({ value, onChange }) {
  return (
    <div className="btn-group">
      <Button.Group>
        <Button
          className="qa-sticker-padding-select-small"
          active={value === stickerPaddings.small}
          icon
          onClick={e => onChange(e, stickerPaddings.small)}
        >
          3px
        </Button>
        <Button
          className="qa-sticker-padding-select-large"
          active={value === stickerPaddings.large}
          icon
          onClick={e => onChange(e, stickerPaddings.large)}
        >
          5px
        </Button>
      </Button.Group>
    </div>
  );
}

StickerPaddingSelect.defaultProps = {
  value: stickerPaddings.large,
};

StickerPaddingSelect.propTypes = {
  value: number,
  onChange: func.isRequired,
};

export default StickerPaddingSelect;
