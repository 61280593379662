import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import { shape, string } from 'prop-types';
import {
  Button,
  ListItem,
  ListIcon,
  ListContent,
  List,
} from 'semantic-ui-react';
import * as Sentry from '@sentry/browser';
import Spinner from 'react-bootstrap/Spinner';

import { fetchStocks } from '../../actions/images';
import { fetchTemplates } from '../../modules/templates';
import { resetViewToSpreadIndex, updateControls } from '../../modules/controls';
import { fetchAlbum } from '../../modules/albums';
import Viewport from '../../components/svg/Viewport';
import Node from '../../components/svg/Node';
import { ImageContext } from '../../components/ImageContext';
import {
  getSpreadsCount,
  getStickerCount,
  getWorkspace,
  selectSingleSpreadWorkspace,
} from '../../selectors/legacy';
import useViewportState from '../../components/svg/Viewport/useViewportState';
import { findTargetSpreadIdOnWorkspace } from '../../util/generators';
import { fetchComments, getPendingComments } from '../../modules/comments';
import AlbumCommentForm from './AlbumCommentForm';
import { fetchProfile } from '../../modules/user';
import useImageLoading from './useImageLoading';

function AlbumPreview(props) {
  const [err, setErr] = useState(null);
  const [previewStickers, setPreviewStickers] = useState(true);
  const [commentFormPosition, setCommentFormPosition] = useState(null);
  const [spreadIndex, setSpreadIndex] = useState(0);
  const [isMounted, setIsMounted] = useState(false);

  const dispatch = useDispatch();
  const { zoom, setZoom } = useViewportState();

  const spreadsCount = useSelector(getSpreadsCount);
  const workspace = useSelector(getWorkspace);
  const singleSpreadWorkspace = useSelector(
    selectSingleSpreadWorkspace(spreadIndex)
  );
  const stickersCount = useSelector(getStickerCount);
  const pendingComments = useSelector(getPendingComments);

  const { imagesLoaded } = useImageLoading(spreadIndex, isMounted);

  const {
    match: {
      params: { albumId },
    },
    location: { search },
  } = props;

  const queryParams = new URLSearchParams(search);
  const readOnly = queryParams.get('readOnly') === 'true';

  useMount(() => {
    const init = async () => {
      try {
        await Promise.all([
          dispatch(fetchStocks()),
          dispatch(fetchTemplates()),
          dispatch(fetchAlbum(albumId)),
          dispatch(fetchComments(albumId)),
          dispatch(fetchProfile()),
        ]);
        dispatch(resetViewToSpreadIndex(spreadIndex));
        dispatch(updateControls({ cropPreview: true }));
        setIsMounted(true);
      } catch (e) {
        Sentry.captureException(e);
        setErr(e);
      }
    };

    init();
  });

  function handleViewportClick(event) {
    if (readOnly) {
      return;
    }

    const { viewportX, viewportY } = event;

    const { spreadId, positionOnSpread } = findTargetSpreadIdOnWorkspace(
      { x: viewportX, y: viewportY },
      workspace
    );

    const { x, y } = positionOnSpread;

    setCommentFormPosition({
      viewportX,
      viewportY,
      spreadId,
      spreadX: x,
      spreadY: y,
    });
  }

  if (err) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          flexDirection: 'column',
        }}
      >
        <h1>Das hat nicht funktioniert</h1>
        <p>Versuche es noch einmal.</p>
      </div>
    );
  }

  function handlePrevSpread() {
    setSpreadIndex(spreadIndex - 1);
    dispatch(resetViewToSpreadIndex(spreadIndex - 1));
  }

  function handleNextSpread() {
    setSpreadIndex(spreadIndex + 1);
    dispatch(resetViewToSpreadIndex(spreadIndex + 1));
  }

  return (
    <ImageContext.Provider
      value={{
        rendering: readOnly, // Using `rendering` to permit/prevent comments CRUD
        stickerRendering: false,
        printing: true,
        resolution: 'full',
        previewSticker: previewStickers,
      }}
    >
      <div role="presentation" className="album-preview-wrapper">
        <Viewport onClick={event => handleViewportClick(event)}>
          <Node
            id={singleSpreadWorkspace.root}
            nodeIndex={spreadIndex}
            nodeSiblingCount={spreadsCount}
          />
          {commentFormPosition && (
            <AlbumCommentForm
              position={commentFormPosition}
              onClose={() => setCommentFormPosition(null)}
              albumId={albumId}
            />
          )}
        </Viewport>
        {!imagesLoaded && (
          <div className="top-controls">
            <div className="image-loading-indicator qa-image-loading-indicator">
              <Spinner
                size="sm"
                animation="border"
                role="status"
                variant="dark"
              />
              <span>Bilder werden geladen</span>
            </div>
          </div>
        )}
        <div className="spread-controls qa-preview-spread-controls">
          <div>
            <div className="preview-metadata">
              <List relaxed>
                <ListItem>
                  <ListIcon name="users" verticalAlign="middle" />
                  <ListContent>{stickersCount} Sticker</ListContent>
                </ListItem>
                <ListItem>
                  <ListIcon name="marker" verticalAlign="middle" />
                  <ListContent>{pendingComments.length} Kommentare</ListContent>
                </ListItem>
              </List>
            </div>
          </div>
          <div>
            <Button.Group>
              <Button
                icon="arrow left"
                onClick={() => handlePrevSpread()}
                disabled={spreadIndex === 0}
              />
              <Button
                className="qa-next-spread"
                icon="arrow right"
                onClick={() => handleNextSpread()}
                disabled={spreadIndex === spreadsCount - 1}
              />
            </Button.Group>
          </div>
          <div>
            <Button onClick={() => setPreviewStickers(!previewStickers)}>
              Sticker {previewStickers ? 'ausblenden' : 'anzeigen'}
            </Button>

            <Button.Group>
              <Button
                icon="zoom in"
                onClick={() => {
                  setZoom(zoom * 1.1);
                }}
              />
              <Button
                icon="zoom out"
                onClick={() => {
                  setZoom(zoom * 0.9);
                }}
              />
            </Button.Group>
          </div>
        </div>
      </div>
    </ImageContext.Provider>
  );
}

AlbumPreview.defaultProps = {};

AlbumPreview.propTypes = {
  match: shape({
    params: shape({
      albumId: string,
    }),
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,
};

export default AlbumPreview;
