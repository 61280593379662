import isEqual from 'lodash/isEqual';
import {
  createSelector,
  createSelectorCreator,
  defaultMemoize,
} from 'reselect';

import {
  actions,
  defaultStickerColor,
  dimensions,
  imageTypes,
  itemTypes,
  operations,
  stickerColorIndex,
} from '../constants';
import { pointToSpreadIndex } from '../util/generators';
import Point from '../util/Point';
import { denormalizeWorkspace, normalizeElement } from '../util/workspace';
import { getImage } from './images';

// Create memoized selector with deep comparison performed using `isEqual` by lodash
const createMemoizedSelectorWithDeepComparison = createSelectorCreator(
  defaultMemoize,
  isEqual
);

export const getIsolation = state => state.controls.isolation;

export const getSections = state => state.workspaceAndSections.present.sections;

export const getStickers = createSelector([getSections], sections =>
  sections.flatMap(section => section.stickers)
);

export const getStickerIds = createSelector([getStickers], stickers =>
  stickers.map(sticker => sticker.id)
);

export const getWorkspace = createSelector(
  [state => state.workspaceAndSections.present.workspace],
  workspace => workspace
);

export const selectSingleSpreadWorkspace = spreadIndex =>
  createSelector([getWorkspace], workspace => {
    const spreads = denormalizeWorkspace(workspace);
    if (spreads.length > 0) {
      return normalizeElement(spreads[spreadIndex]);
    }
    return workspace;
  });

export const getLegacySpreads = createSelector([getWorkspace], workspace =>
  denormalizeWorkspace(workspace)
);

export const getSpreadIds = createSelector(
  [getWorkspace],
  ({ nodes, root }) => nodes[root].children
);

export const getSpreadNodes = createSelector(
  [getWorkspace],
  ({ nodes, root }) => nodes[root].children.map(childId => nodes[childId])
);

export const getSpreadsCount = createSelector(
  [getSpreadIds],
  spreadIds => spreadIds.length
);

export const makeGetSelectedIds = itemType =>
  createSelector([state => state.selection], selection => selection[itemType]);

export const getSelectedSpreadIds = makeGetSelectedIds(itemTypes.spread);

export const getSelectedStickerIds = makeGetSelectedIds(itemTypes.sticker);

export const getSelectedSectionIds = makeGetSelectedIds(itemTypes.section);

export const internallyGetSelectedElementIds = makeGetSelectedIds(
  itemTypes.element
);

export const internallyGetAllNodeIds = createSelector(
  [getWorkspace],
  ({ nodes }) => Object.keys(nodes)
);

export const getSelectedElementIds = createMemoizedSelectorWithDeepComparison(
  [internallyGetSelectedElementIds, internallyGetAllNodeIds],
  (selectedIds, getAllNodeIds) =>
    selectedIds.filter(id => getAllNodeIds.includes(id))
);

export const getSelectedSections = createSelector(
  [getSelectedSectionIds, getSections],
  (selectedIds, sections) =>
    sections.filter(item => selectedIds.includes(item.id))
);

export const getSelectedElements = createSelector(
  [getSelectedElementIds, getWorkspace],
  (selectedElementIds, { nodes }) => selectedElementIds.map(id => nodes[id])
);

export const getSingleSelectedElement = state => {
  const selectedElements = getSelectedElements(state);
  const isSingleElement = selectedElements.length === 1;
  if (!isSingleElement) return null;

  const [element] = selectedElements;

  // Return the element itself if the select element is not an image
  const isImage = element.type === 'Image';
  if (!isImage) return element;

  const { image: imageId } = element.props;
  return {
    ...element,
    props: {
      ...element.props,
      imageObject: getImage(state, imageId),
    },
  };
};

export const getSelectedTextElements = createSelector(
  [getSelectedElements],
  selectedElements => selectedElements.filter(item => item.type === 'Text')
);

export const getAllNodes = createSelector([getWorkspace], ({ nodes }) =>
  Object.values(nodes)
);

export const getAllTextNodes = createSelector([getAllNodes], elements =>
  elements.filter(item => item.type === 'Text')
);

export const getSelectedImages = createSelector(
  [getSelectedElements],
  selectedElements =>
    selectedElements.map(item => item.props.image).filter(item => item)
);

export const getSelectedStickers = createSelector(
  [getStickers, getSelectedStickerIds],
  (stickers, selectedStickerIds) =>
    stickers.filter(sticker => selectedStickerIds.includes(sticker.id))
);

export const getSingleSelectedSticker = createSelector(
  [getSelectedStickers],
  selectedStickers => {
    if (selectedStickers.length !== 1) {
      return null;
    }

    const [singleSelectedSticker] = selectedStickers;
    return singleSelectedSticker;
  }
);

export const getStickerById = createSelector(
  [getStickers, (_, id) => id],
  (stickers, id) => stickers.find(sticker => sticker.id === id)
);

export const getSimplifiedImageNodes = createSelector([getAllNodes], nodes =>
  nodes
    .filter(({ type }) => type === 'Image')
    .map(({ type, props }) => ({
      type,
      props: { id: props.id, image: props.image },
    }))
);

export const getImageUsage = createMemoizedSelectorWithDeepComparison(
  [getSimplifiedImageNodes, getStickers],
  (imageNodes, stickers) => {
    const workspaceUsage = imageNodes.reduce((acc, { props: { image } }) => {
      acc[image] = acc[image] + 1 || 1;
      return acc;
    }, {});

    return stickers
      .filter(sticker => sticker.logo)
      .reduce((acc, { logo }) => {
        acc[logo] = acc[logo] + 1 || 1;
        return acc;
      }, workspaceUsage);
  }
);

export const getApplicableOperations = createSelector(
  [
    getSingleSelectedElement,
    getSelectedElementIds,
    getSelectedStickerIds,
    state => state.selection.selectInside,
  ],
  (singleSelectedElement, selectedElements, selectedStickers, inside) => {
    const numSelectedElements = selectedElements.length;
    const numSelectedStickers = selectedStickers.length;
    if (numSelectedStickers === 1) {
      // single sticker
      return [operations.move, operations.rotate, operations.scale];
    }
    if (singleSelectedElement) {
      // single element
      switch (singleSelectedElement.type) {
        case 'Rectangle':
        case 'Circle':
          return [
            operations.move,
            operations.rotate,
            operations.resize,
            operations.scale,
          ];
        case 'Symbol':
          return [operations.move];
        case 'Line':
          return [operations.move, operations.rotate, operations.resize];
        case 'Group':
          return [operations.move, operations.rotate, operations.scale];
        case 'StickerArea':
          return [operations.move, operations.rotate, operations.resize];
        case 'Image':
          if (inside) {
            return [operations.move, operations.rotate, operations.scale];
          }

          let fixed = false;
          const { contentType, ratio } = singleSelectedElement.props;
          if (contentType === imageTypes.ad) {
            switch (ratio) {
              case 'ad_s_p':
              case 'ad_m_p':
              case 'ad_l_p':
              case 'ad_s_l':
              case 'ad_m_l':
              case 'ad_l_l':
              case 'ad_xl_border':
                fixed = true;
                break;
              default:
                fixed = false;
            }
          }

          if (!fixed) {
            return [
              operations.move,
              operations.rotate,
              operations.resize,
              operations.scale,
            ];
          }
          return [operations.move];

        case 'Text':
          if (inside) {
            return [operations.resize];
          }
          return [
            operations.move,
            operations.rotate,
            operations.resize,
            operations.scale,
          ];

        default:
          return [];
      }
    } else if (numSelectedElements > 1) {
      // multiple element
      return [
        operations.move,
        operations.rotate,
        operations.resize,
        operations.scale,
      ];
    }
    return [];
  }
);

export const getApplicableProps = createMemoizedSelectorWithDeepComparison(
  [
    getSingleSelectedElement,
    getSelectedElementIds,
    getSelectedStickerIds,
    state => state.selection.selectInside,
  ],
  (singleSelectedElement, selectedElements, selectedStickers, inside) => {
    const numSelectedElements = selectedElements.length;
    const numSelectedStickers = selectedStickers.length;
    if (singleSelectedElement) {
      switch (singleSelectedElement.type) {
        case 'Rectangle':
        case 'Circle':
          return [
            'fill',
            'stroke',
            'opacity',
            'x',
            'y',
            'width',
            'height',
            'rotation',
            'scale',
            'align',
          ];

        case 'Line':
          return [
            'stroke',
            'opacity',
            'x',
            'y',
            'width',
            'rotation',
            'scale',
            'align',
          ];

        case 'Symbol':
          return ['fill', 'x', 'y'];

        case 'Group':
          return [
            'filter',
            'opacity',
            'x',
            'y',
            'width',
            'height',
            'rotation',
            'scale',
            'align',
          ];

        case 'StickerArea':
          return [
            'stickerPadding',
            'stickerPlacing',
            'x',
            'y',
            'width',
            'height',
            'align',
            'rotation',
          ];

        case 'Image':
          let props = ['filter', 'fillAndBlend', 'opacity', 'contentType'];
          const { contentType, ratio } = singleSelectedElement.props;
          if (inside) {
            props = [...props, 'cx', 'cy', 'crotation', 'cscale'];
          } else {
            props = [...props, 'x', 'y'];
            if (
              contentType === imageTypes.ad ||
              contentType === imageTypes.background ||
              contentType === imageTypes.title ||
              contentType === imageTypes.common ||
              contentType === imageTypes.filler
            ) {
              props = [...props, 'ratio'];
              let fixed;
              switch (ratio) {
                case 'ad_s_p':
                case 'ad_m_p':
                case 'ad_l_p':
                case 'ad_s_l':
                case 'ad_m_l':
                case 'ad_l_l':
                  fixed = contentType === imageTypes.ad;
                  break;
                default:
                  fixed = false;
              }
              if (!fixed) {
                props = [
                  ...props,
                  'stroke',
                  'width',
                  'height',
                  'rotation',
                  'scale',
                ];
              }
            } else {
              props = [
                ...props,
                'stroke',
                'width',
                'height',
                'rotation',
                'scale',
              ];
            }
            props = [...props, 'align'];
          }
          return props;

        case 'Text':
          return [
            'text',
            'opacity',
            'x',
            'y',
            'width',
            'height',
            'rotation',
            'scale',
            'align',
            'symbol',
          ];
        default:
          console.error('type not found:', singleSelectedElement.type);
          return [];
      }
    } else if (numSelectedElements > 1) {
      // multiple elements
      return ['filter', 'text', 'fill', 'stroke', 'opacity', 'align'];
    } else if (numSelectedStickers > 0) {
      return [
        'filter',
        'fill',
        'cx',
        'cy',
        'crotation',
        'cscale',
        'sticker_tag',
      ];
    } else {
      return [];
    }
  }
);

export const getApplicableActionsFromItemType = (
  itemType,
  selectInside,
  singleSelectedElement
) => {
  const spreadActions = [
    actions.moveSpreadItemsUp,
    actions.moveSpreadItemsDown,
    actions.createSpreadBefore,
    actions.createSpreadAfter,
    actions.deleteSpreadItems,
  ];

  if (itemType === itemTypes.element) {
    if (singleSelectedElement) {
      switch (singleSelectedElement.type) {
        case 'Symbol':
        case 'Rectangle':
        case 'Circle':
        case 'Line':
        case 'StickerArea':
          return [
            actions.sendItemsBack,
            actions.sendItemsFront,
            actions.duplicateItems,
            actions.deleteElementItems,
            actions.convertToLocalColors,
            ...spreadActions,
          ];

        case 'Group': {
          const isTemplate = singleSelectedElement.props.template;
          const baseActions = [
            ...spreadActions,
            actions.sendItemsBack,
            actions.sendItemsFront,
            actions.duplicateItems,
            actions.deleteElementItems,
            actions.ungroupItems,
            actions.convertToLocalColors,
          ];
          if (isTemplate) {
            return [
              ...baseActions,
              actions.updateAllInstancesFromInstance,
              actions.updateTemplateFromInstance,
            ];
          }
          return [...baseActions, actions.createTemplateFromGroup];
        }
        case 'Image':
          // TODO pdf-images are not flippable
          return [
            ...spreadActions,
            actions.sendItemsBack,
            actions.sendItemsFront,
            actions.duplicateItems,
            actions.deleteElementItems,
            actions.fitContentCover,
            actions.fitContentContain,
            actions.fitFrame,
            actions.flipImage,
          ];

        case 'Text': {
          if (selectInside) {
            return [];
          }
          const textActions = [
            ...spreadActions,
            actions.sendItemsBack,
            actions.sendItemsFront,
            actions.duplicateItems,
            actions.deleteElementItems,
            actions.convertToLocalColors,
          ];
          if (
            singleSelectedElement.props.symbol &&
            singleSelectedElement.props.symbol !== 'pagenum'
          ) {
            return [...textActions, actions.convertToEditableText];
          }
          return textActions;
        }
        default:
          console.error('type not found:', singleSelectedElement.type);
          return [];
      }
    } else {
      // multiple elements
      return [
        ...spreadActions,
        actions.sendItemsBack,
        actions.sendItemsFront,
        actions.duplicateItems,
        actions.deleteElementItems,
        actions.groupItems,
        actions.convertToLocalColors,
      ];
    }
  }

  if (itemType === itemTypes.sticker) {
    return [
      actions.zoomSticker,
      actions.scaleStickersIn,
      actions.scaleStickersOut,
      actions.autoAlignSticker,
      actions.clearStickerLogo,
      actions.uploadSticker,
      actions.downloadSticker,
    ];
  }

  if (itemType === itemTypes.section) {
    return [actions.zoomSection, actions.selectStickersInSection];
  }

  if (itemType === itemTypes.spread) {
    return spreadActions;
  }

  return [];
};

export const getApplicableActions = createSelector(
  [
    state => getSelectedElementIds(state).length > 0,
    state => getSelectedStickerIds(state).length > 0,
    state => state.selection.selectInside,
    getSingleSelectedElement,
  ],
  (
    selectedElementsPresent,
    selectedStickersPresent,
    selectInside,
    singleSelectedElement
  ) => {
    let itemType = null;
    if (selectedElementsPresent) {
      itemType = itemTypes.element;
    } else if (selectedStickersPresent) {
      itemType = itemTypes.sticker;
    }

    return getApplicableActionsFromItemType(
      itemType,
      selectInside,
      singleSelectedElement
    );
  }
);

export const getLocalStyleMap = text => {
  const styleNames = text.blocks.flatMap(block =>
    (block.inlineStyleRanges || []).map(range => range.style)
  );

  const relevantStyleNames = styleNames.filter(
    item =>
      item.indexOf('COLOR-#') === 0 || item.indexOf('LETTERSPACING-') === 0
  );

  const styleMap = relevantStyleNames.reduce((result, name) => {
    const i = name.indexOf('-');
    if (i !== -1) {
      const a0 = name.substring(0, i);
      const a1 = name.substring(i + 1);
      if (a0 === 'COLOR') {
        result[name] = { color: a1 };
      } else if (a0 === 'LETTERSPACING') {
        result[name] = { letterSpacing: `${a1}pt` };
      }
    }
    return result;
  }, {});

  return styleMap;
};

export const getGlobalStyleMap = createSelector(
  [state => state.colors],
  colors =>
    colors.reduce((result, color) => {
      const index = colors.indexOf(color);
      // eslint-disable-next-line no-param-reassign
      result[`COLOR-${index}`] = { color: `${color}` };
      return result;
    }, {})
);

export const getStickerCount = createSelector([getStickers], stickers =>
  stickers.reduce((acc, sticker) => acc + (sticker.doubleSticker ? 2 : 1), 0)
);

/*
This is calculating values used in the preflight-check before a PDF is rendered. In order for a print, the page-count
needs to be dividable by four, so the number of inlay-spreads (2 pages each) needs to be dividable by 2:
(see https://opusdesign.us/wordcount/printing-multiples-of-four/)
 */
export const getAlbumStatisticsForPreflight = createSelector(
  [getSpreadsCount],
  spreadCount => {
    const inlaySpreadsCount = spreadCount > 0 ? spreadCount - 1 : 0;

    // Calculating the book spine, based on a formula from our print shop
    let spine = Math.ceil((inlaySpreadsCount - 2) * 0.14 * 10) / 10;
    if (spine < 0) {
      spine = 0;
    }

    // The number of inlay-spreads needed until the number of inlay-spreads is dividable by 2
    const spreadsNeeded = inlaySpreadsCount % 2;

    return { inlaySpreadsCount, spreadsNeeded, spine };
  }
);

export const getViewCenterIndex = createSelector(
  [getSpreadsCount, state => state.controls.pan, state => state.controls.zoom],
  (spreadsCount, pan, zoom) => {
    const { clientHeight } = document.documentElement;
    const viewCenter = new Point(0, pan.y + clientHeight / zoom / 2);

    const targetSpreadIndex = pointToSpreadIndex(viewCenter, spreadsCount);
    return targetSpreadIndex;
  }
);

export const getTargetSpreadId = createSelector(
  [
    getWorkspace,
    getViewCenterIndex,
    getSelectedSpreadIds,
    getSelectedElementIds,
    getIsolation,
  ],
  (
    { nodes, root },
    viewCenterIndex,
    selectedSpreadIds,
    selectedElementIds,
    isolation
  ) => {
    if (isolation) {
      return nodes[isolation].parent;
    }
    if (selectedElementIds.length > 0) {
      return nodes[selectedElementIds[0]].parent;
    }
    if (selectedSpreadIds.length > 0) {
      return selectedSpreadIds[0];
    }
    if (viewCenterIndex !== -1) {
      const viewCenterSpreadId = nodes[root].children[viewCenterIndex];
      if (!viewCenterSpreadId) {
        return root;
      }
      return viewCenterSpreadId;
    }
    return root;
  }
);

export const getTargetSpreadIndex = createSelector(
  [getWorkspace, getTargetSpreadId],
  ({ nodes, root }, targetSpreadId) =>
    nodes[root].children.indexOf(targetSpreadId)
);

export const getTargetNodeId = createSelector(
  [getIsolation, getTargetSpreadId],
  (isolation, targetSpreadId) => isolation || targetSpreadId
);

export const getTargetNode = createSelector(
  [getWorkspace, getTargetNodeId],
  ({ nodes }, targetNodeId) => nodes[targetNodeId]
);

export const getLockedElementIds = createSelector(
  [getTargetNodeId, getWorkspace],
  (nodeId, { nodes }) =>
    nodes[nodeId].children.filter(id => nodes[id].props.locked)
);

export const makeLoadingSelector = blocking =>
  createSelector([state => state.loading], loading => {
    return loading.filter(item => item.blocking === blocking).length > 0;
  });

export const getAppearanceFromProps = createSelector(
  [
    props => props.fill,
    props => props.fillOpacity,
    props => props.stroke,
    props => props.strokeWidth,
    props => props.opacity,
    props => props.colors,
    props => props.resolvedStickerColor,
  ],
  (
    fill,
    fillOpacity,
    stroke,
    strokeWidth,
    opacity,
    colors,
    resolvedStickerColor
  ) => {
    if (fill === stickerColorIndex) {
      fill = resolvedStickerColor || defaultStickerColor;
    } else if (fill !== null && fill !== undefined) {
      if (typeof fill === 'number') {
        fill = colors[fill];
      }
    } else {
      fill = 'none';
    }

    if (stroke !== null && stroke !== undefined) {
      if (typeof stroke === 'number') {
        stroke = colors[stroke];
      }
    } else {
      stroke = 'none';
    }
    return { fill, fillOpacity, stroke, strokeWidth, opacity };
  }
);

export const getVisibleSpreadIds = createSelector(
  [state => state.controls.zoom, getViewCenterIndex, getWorkspace],
  (zoom, viewCenterIndex, workspace) => {
    const { clientHeight } = document.documentElement;
    const pagesOnScreen = Math.ceil(
      clientHeight / zoom / (dimensions.pageHeight + dimensions.pagePadding)
    );
    let visibleRange = pagesOnScreen / 2;
    if (visibleRange < 1) {
      visibleRange = 1;
    }

    const { children } = workspace.nodes[workspace.root];

    return children.filter(
      (id, index) =>
        index >= viewCenterIndex - visibleRange &&
        index <= viewCenterIndex + visibleRange
    );
  }
);

export const getInteractiveParentIds = createSelector(
  [getSpreadIds, getIsolation],
  (spreadIds, isolation) => {
    if (isolation) {
      return [isolation];
    }
    return spreadIds;
  }
);

export const getStickersById = createSelector([getStickers], stickers => {
  return stickers.reduce((acc, sticker) => {
    acc[sticker.id] = sticker;
    return acc;
  }, {});
});
