import { node, string } from 'prop-types';
import React from 'react';

import { dimensions } from '../../../../constants';
import { resolveImage } from '../../../../util/images';
import {
  ImageContextShape,
  ImageObjectShape,
  StickerShape,
} from '../../../shapes';
import SvgImage from '../../SvgImage';

function StickerImageDouble({
  sticker,
  imageObject,
  filterDefinitions,
  imageContext,
  clipPath,
}) {
  const hasFilterDefinitions = !!filterDefinitions;

  let image = null;

  if (imageObject) {
    const { src } = resolveImage(imageObject, imageContext.resolution);
    const { meta } = imageObject;
    const scale = Math.min(
      (dimensions.stickerWidth * 2) / meta.width,
      dimensions.stickerHeight / meta.height
    );
    // actualWidth / actualHeight: actual image dimensions fitted into a box of 100x70 (double sticker)
    const actualWidth = meta.width * scale;
    const actualHeight = meta.height * scale;
    image = (
      <SvgImage
        id={sticker.id}
        src={src}
        width={actualWidth}
        height={actualHeight}
        filters={hasFilterDefinitions ? `url(#filter-${sticker.id})` : ''}
        rendering={imageContext.rendering}
        preserveAspectRatio="xMidYMid slice"
      />
    );
  }

  return (
    <g>
      <g clipPath={clipPath}>
        <rect
          width={dimensions.stickerWidth * 2}
          height={dimensions.stickerHeight}
          fill="#fff"
        />
        <g
          transform={`translate(${sticker.cx ?? 0} ${sticker.cy ??
            0}) scale(${sticker.cscale ?? 1}) rotate(${sticker.crotation ??
            0})`}
          className="inside"
          data-id={sticker.id}
        >
          {hasFilterDefinitions && <defs>{filterDefinitions}</defs>}
          {image}
        </g>
      </g>

      {!imageContext.rendering && (
        <line
          x1={dimensions.stickerWidth}
          y1={0}
          x2={dimensions.stickerWidth}
          y2={dimensions.stickerHeight}
          stroke="black"
          strokeWidth={0.1}
        />
      )}
    </g>
  );
}

StickerImageDouble.defaultProps = {
  filterDefinitions: null,
  imageObject: null,
};

StickerImageDouble.propTypes = {
  sticker: StickerShape.isRequired,
  imageContext: ImageContextShape.isRequired,
  imageObject: ImageObjectShape,
  filterDefinitions: node,
  clipPath: string.isRequired,
};

export default StickerImageDouble;
