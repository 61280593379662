import { useEffect, useState } from 'react';

function useImageLoading(spreadIdx, domReady) {
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    setImagesLoaded(false);

    /**
     * The current spread might include images without a (valid) src attribute.
     * We filter them out here to not block the loading state.
     */
    const nodeList = document.querySelectorAll('.svg-image');
    const images = Array.from(nodeList).filter(img => img.href.animVal);

    let loadedCount = 0;
    const handleImageLoad = () => {
      loadedCount += 1;
      if (loadedCount === images.length) {
        setImagesLoaded(true);
      }
    };

    if (images.length === 0) {
      setImagesLoaded(true);
      return () => {};
    }

    images.forEach(img => img.addEventListener('load', handleImageLoad));

    return () => {
      images.forEach(img => img.removeEventListener('load', handleImageLoad));
    };
  }, [spreadIdx, domReady]);

  return {
    imagesLoaded,
  };
}

export default useImageLoading;
